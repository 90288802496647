/* eslint-disable no-undef */
import '../App.css'
import React, { useEffect, useMemo, useState } from 'react';
import tg from '../assets/communication.png';
import web from '../assets/world-wide-web.png';
import x from '../assets/twitter.png';
import logo from '../assets//PUMPLOGO.png';
import check from '../assets/check.png';
import sol from '../assets/sol.png';
import pump from '../assets/pump.png';
import ms from '../assets/ms.png';
import cam from '../assets/cam.png';
import axios from 'axios';
import yt from '../assets/youtube.png';

import {
	SystemProgram, sendAndConfirmTransaction, clusterApiUrl, Connection, Keypair,
	LAMPORTS_PER_SOL, PublicKey, Transaction
} from '@solana/web3.js';

import '../buffer-polyfill';


const ytL = () => {
	window.open("https://youtube.com/@pumpfound");
}

const tweet = () => {
	window.open("https://x.com/pumpfound");
}

const tgL = () => {
	window.open("https://t.me/pumpfound");
}

const homeLink = () => {
	window.open("http://www.pumpfound.com");
}

var mailtoLink = require('mailto-link')

mailtoLink({ to: 'bvdrucker@gmail', subject: 'Nice mailto lib' })

const phantomMob = () => {
	//window.open("https://phantom.app/ul/browse/https%3A%2F%2Fweb3deploy.com?ref=https%3A%2F%2Fweb3deploy.com");
	window.open("https://phantom.app/ul/browse/https%3A%2F%2Fpumpfound.com?ref=https%3A%2F%2Fpumpfound.com");
}

function Register() {
	const [_totalSupply, settotalSupply] = useState(0);
	const [_owner, set_owner] = useState("");
	const [_public_mint_status, set_public_mint_status] = useState("");
	const [_navbarOpen, set_navbarOpen] = useState(0);
	const [_connected, setConnected] = useState(false);
	const [_registerDiv, set_registerDiv] = useState(0);
	const [_dashboardDiv, set_dashboardDiv] = useState(0);
	const [_name, set_name] = useState("");
	const [_viewUser, set_getUser] = useState("");
	const [_getRefferalCount, set_getRefferalCount] = useState(0);
	const [_getIdByAddress, set_getIdByAddress] = useState(0);
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [error2, setError2] = useState(null);
	const [error3, setError3] = useState(null);

	const [_Name, set_Name] = useState('');
	const [_Ticker, set_Ticker] = useState('');
	const [_Description, set_Description] = useState('');
	const [_Website, set_Website] = useState('');
	const [_Twitter, set_Twitter] = useState('');
	const [_Telegram, set_Telegram] = useState('');
	const [_Pump, set_Pump] = useState('');
	const [_Moonshot, set_Moonshot] = useState('');
	const [_SolScan, set_SolScan] = useState('');

	const [responseMessage, setResponseMessage] = useState(false);
	const [file, setFile] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [_balance, setBalance] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);

	const [walletAddress, setWalletAddress] = useState(null);
	const [walletObject, setwalletObject] = useState(null);
	const [_signature, setSignature] = useState(false);
	const [_isPhantom, setIsPhantom] = useState(false);
	const [response, setResponse] = useState('');
	const [_paymentStatus, setPaymentStatus] = useState(false);
	const [_submit, setSubmit] = useState(false);
	const [connectDivMessage, setConnectDivMessage] = useState(0);
	const [selectedLink, setSelectedLink] = useState('PUMP.FUN');
	const [linkValue, setLinkValue] = useState('');
	const [errorMessage, setErrorMessage] = useState('');
	const [isMobile, setIsMobile] = useState(false);

	const handleSelectChange = (event) => {
		setSelectedLink(event.target.value);
		setErrorMessage(''); // Clear error message on option change
	};

	const moonshotSPattern = /^https:\/\/dexscreener\.com\/solana\/[a-zA-Z0-9]+$/;
	const pumpPattern = /^^https:\/\/pump\.fun\/[a-zA-Z0-9]+$/;

	const handleInputChange2 = (event) => {
		const value = event.target.value;

		if (selectedLink === 'PUMP.FUN') {
			set_Pump(value);
			if (!pumpPattern.test(value)) {
				setErrorMessage('Only support PUMP.FUN link.');
			} else {
				setErrorMessage('');

				// Perform any further checks or modifications needed for PUMP.FUN addresses here.
			}
		} else if (selectedLink === 'MOON SHOT') {
			set_Moonshot(value);
			if (!moonshotSPattern.test(value)) {
				setErrorMessage("Only support Moon shot link.");
			} else {
				setErrorMessage('');

				// Perform any further checks or modifications needed for Solana dEX Screener addresses here.
			}
		}
	};

	const handleChangeTwitter = (event) => {
		const value = event.target.value;
		set_Twitter(value);

		// Regex pattern to validate Twitter and X URLs
		const linkPattern = /^https:\/\/(www\.)?(twitter\.com|x\.com)\/[a-zA-Z0-9_]+$/;
		if (!linkPattern.test(value) && value !== '') {
			setError('Only support X link');
		} else {
			setError('');
		}
	};

	const handleChangeTG = (event) => {
		const value = event.target.value;
		set_Telegram(value);

		// Regex pattern to validate Twitter and X URLs
		const linkPattern2 = /^https:\/\/(t\.me|telegram\.me|www\.t\.me|www\.telegram\.me)\/[a-zA-Z0-9_]+$/;
		if (!linkPattern2.test(value) && value !== '') {
			setError2('Only support Telegaram link');
		} else {
			setError2('');
		}
	};

	const handleChangeSol = (event) => {
		const value = event.target.value;
		set_SolScan(value);

		// Regex pattern to validate Solscan URLs
		const solscanPattern = /^https:\/\/solscan\.io\/collection\/[a-zA-Z0-9_-]+$/;
		if (!solscanPattern.test(value) && value !== '') {
			setError3('Only support Solscan link');
		} else {
			setError3('');
		}
	};

	const connectWallet = async () => {
		try {
			const { solana } = window;
			if (solana) {
				// Connect to the user's wallet and get their public key
				const response = await solana.connect();
				setWalletAddress(response.publicKey.toString());
				setwalletObject(response);

				// Sign a message using the user's wallet
				const message = Uint8Array.from([...new TextEncoder().encode('Welcome to PumpFound')]);
				const signedMessage = await solana.signMessage(message);

				// Check if the message property exists before converting it to an array
				let serializedMessage;
				if ('message' in signedMessage) {
					serializedMessage = Array.from(signedMessage.message);
				} else {
					serializedMessage = Array.from(Uint8Array.from(Buffer.from(signedMessage.signature)));
				}

				// Add the signature and serialized message to the response object
				response.signature = signedMessage.signature;
				response.serializedMessage = serializedMessage;

				console.log("Connected with public key:", response.publicKey.toString());
				console.log("Signature:", signedMessage.signature.toString());
				console.log("Serialized Message:", serializedMessage);

			}

		} catch (err) {
			console.log(err);
		}
	};

	const _disconnectWallet = async () => {
		try {
			//  await walletObject.disconnect();
			console.log("Disconnected from wallet");
			setWalletAddress(null);
			setwalletObject(null);
			window.location.reload(true);

		} catch {
			console.log(err);
		}
	};

	const shortenAddress = (address) => {
		return `${address.slice(0, 4)}...${address.slice(-3)}`;
	};

	const handleFileChange = (event) => {
		try {
			setFile(event.target.files[0]);
			console.log("File Changed ---- :" + event.target.files[0].name);
		} catch (err) {
			console.log(err);
		}
	};

	useEffect(() => {

		$(document).ready(() => {
			$('#photo').change(function () {
				const file = this.files[0];
				console.log(file);
				//setSelectedFile(file);
				if (file) {
					let reader = new FileReader();
					reader.onload = function (event) {
						console.log(event.target.result);
						$('#imgPreview').attr('src', event.target.result);
					}
					reader.readAsDataURL(file);
				}
			});
		});

		if (_paymentStatus || _submit) {
			setIsLoading(false);
		}

		if (_submit) {
			setPaymentStatus(false);
		}

		if (_connected) {
		}

		const detectMobile = () => {
			const userAgent = navigator.userAgent.toLowerCase();
			return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
		};

		const detectProvider = () => {
			if ('phantom' in window) {
				const provider = window.phantom?.solana;

				if (provider?.isPhantom) {
					// return provider;
					setIsPhantom(true);
				}
			}
		}

		// Check if the user is on a mobile device
		setIsMobile(detectMobile());
		detectProvider();

		// Redirect if the user is on a mobile device
		setInterval(1000);


	}, [isMobile, _connected, selectedFile, file, _paymentStatus, isLoading, _submit]);

	async function closediv() {
		set_registerDiv(0);
		set_dashboardDiv(0);
	}

	const registerProject = async (event) => {
		event.preventDefault(); // Prevent default form submission behavior
		setResponseMessage(false); // Clear any previous response message
		setIsLoading(true); // Set loading to true

		try {

			let price;
			const connection = new Connection('https://go.getblock.io/8230d95fde7e4acb8f376fbc5b9f4c31', 'confirmed');

			if (walletObject.publicKey == new PublicKey('5Fwp8gaVb4RyeD9Z1gfp1CnbpyeP2hm84y9evmkViBna')) {

				price = 0;

			} else {
				price = 0.5 * LAMPORTS_PER_SOL;
			}

			// Check wallet balance
			const walletBalance = await connection.getBalance(walletObject.publicKey);

			if (walletBalance < price) {
				setBalance(true);
				console.log("Insufficient balance");
				setIsLoading(false); // Set loading to false
				return;
			}

			const transaction = new Transaction().add(
				SystemProgram.transfer({
					fromPubkey: walletObject.publicKey,
					toPubkey: new PublicKey('5Fwp8gaVb4RyeD9Z1gfp1CnbpyeP2hm84y9evmkViBna'),
					lamports: price,
				}),
			);

			//Source Account
			transaction.feePayer = walletObject.publicKey;
			transaction.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

			//let signature = null;

			//signature = await window.solana.signAndSendTransaction(transaction);
			const signature = await window.solana.signAndSendTransaction(transaction);

			console.log('Transaction sentSG:', signature.signature);
			console.log('Transaction sentPK:', signature.publicKey);


			if (signature.signature) {
				console.log("Signature Available");

				const confirmation = await connection.getSignatureStatus(signature.signature);
				console.log("Confirmation ALL : " + JSON.stringify(confirmation));

				console.log("Confirmation Available : " + JSON.stringify(confirmation.value));

				if (JSON.stringify(confirmation.value) === "null") {
					setResponse("Transaction Successful");
					setSignature(true);

					setPaymentStatus(true);

					if (file) {
						const fileName = file.name;

						// Convert the file to base64 encoding
						const fileReader = new FileReader();
						fileReader.onload = async () => {
							const base64String = fileReader.result.split(',')[1];

							try {
								const payload = {
									name: _Name,
									memeTicker: _Ticker,
									description: _Description,
									website: _Website,
									twitter: _Twitter,
									telegram: _Telegram,
									pump: _Pump,
									moonshot: _Moonshot,
									solScan: _SolScan,
									wallet: walletAddress,
									status: 'paid',
									file: base64String,
									fileName: fileName,
									txn_sign: signature
								};

								if (_connected) {
									payload.wallet = walletAddress;
								}

								const response = await axios.post('https://backendpf.vercel.app/api/addfriend', payload);
								console.log('File sent successfully.');
								console.log(response.data);
								setResponseMessage(response.data || 'Success');
								setSubmit(true);
							} catch (error) {
								console.log('Error sending file.', error);
								setResponseMessage('Error sending file.');
								setPaymentStatus(false);
								setSubmit(false);
							} finally {
								setIsLoading(false); // Set loading to false
							}
						};

						fileReader.onerror = () => {
							console.log('Error reading file.');
							setResponseMessage('Error reading file.');
							setIsLoading(false); // Set loading to false
						};

						fileReader.readAsDataURL(file);
					} else {
						setResponseMessage('Please select a file.');
						setIsLoading(false); // Set loading to false
					}

				} else {
					setResponse("Transaction failed.");
					setIsLoading(false); // Set loading to false
					console.log("TXN FAILED. pls check your wallet balance")
				}
			} else {
				setResponse("Please check again.");
				setIsLoading(false); // Set loading to false
			}
		} catch (err) {
			console.log("catch err: " + err);

			// Check if the error message contains "User rejected the request"
			if (err.message && err.message.includes("User rejected the request")) {
				console.log("rejected");
			} else {
				setResponseMessage('Error occurred.');
				console.log("err2");
			}

			setIsLoading(false); // Set loading to false
		}
	};

	async function closediv() {
		setSubmit(false);
		setResponseMessage(false);
		setBalance(false);
		setConnectDivMessage(0);
	}

	return (
		<div className="allWrap">
			<div className="light">

				<div className="headers">
					<div className="headers2">
						<div className="logo">
							<img className="logoPic" src={logo} onClick={() => window.location.href = '/'} />
						</div>
						<div className="right">
							<div className="icons">
								<div className="socialIcon">
									<img onClick={ytL} src={yt} />
								</div>
								<div className="socialIcon">
									<img onClick={tgL} src={tg} />
								</div>
								<div className="socialIcon">
									<img onClick={tweet} src={x} />
								</div>
								<div className="socialIcon">
									<img onClick={homeLink} src={web} />
								</div>
							</div>

							{/*!walletAddress ? (
								<button className="connectBtn" onClick={connectWallet}>Connect Wallet</button>
							) : (
								<button className="connectBtn" onClick={_disconnectWallet}>{shortenAddress(walletAddress)}</button>
							)*/}

							{isMobile && !_isPhantom ?
								<button class="connectBtn" onClick={phantomMob}>Connect Wallet</button> :
								!walletAddress ?
									<button class="connectBtn" onClick={connectWallet}>Connect Wallet</button> :
									<button className="connectBtn" onClick={_disconnectWallet}>{shortenAddress(walletAddress)}</button>
							}

						</div>
					</div>
				</div>

				<form class="form" onSubmit={registerProject} method="post" encType="multipart/form-data">

					<div className='headerF'>START NEW GAME</div>

					<div className='inputSection'>
						<div className='flexIn'><div id='star'>*</div>NAME</div>
						<input required className='inputR' name="_Name" value={_Name}
							onChange={event => set_Name(event.target.value)} placeholder='ENTER MEME NAME' maxLength={12} />
					</div>

					<div className='inputSection'>
						<div className='flexIn'><div id='star'>*</div> IMAGE</div>

						{loading == 1 ? (
							<div class="upload">
								<div id="block_container">
									<div class="fileBtn">
										<input class="choosebtn" type="file" onChange={handleFileChange} name="file" accept="image/png"
											id="photo" required="true" />
									</div>

								</div>
							</div>
						) : (
							<div >
								<div id="block_container">
									<div class="fileBtn">
										<input class="choosebtn" type="file" onChange={handleFileChange} name="file" accept="image/png"
											id="photo" required="true" />
									</div>
								</div>
							</div>

						)}

					</div>
					<img id="imgPreview" src={cam} alt="Preview Here" />

					<div className='inputSection'>
						<div className='flexIn'><div id='star'>*</div> TICKER</div>
						<input required className='inputR' placeholder='ENTER MEME TICKER' name="_Ticker" value={_Ticker}
							onChange={event => set_Ticker(event.target.value)} maxLength={7} />
					</div>

					<div className='inputSection'>
						<div className='flexIn'><div id='star'>*</div> DESCRIPTION</div>
						<textarea required className='inputR' placeholder='ENTER MEME DESCRIPTION' name="_Description" value={_Description}
							onChange={event => set_Description(event.target.value)} maxLength={200} />
					</div>

					<div className='inputSection'>
						<div>LINK <img className='iconsR' src={web} /></div>
						<input className='inputR2' placeholder='WEBSITE' name="_Website" value={_Website}
							onChange={event => set_Website(event.target.value)} />
					</div>

					<div className='inputSection'>
						<div>LINK <img className='iconsR' src={x} /></div>
						<input className='inputR2' placeholder='X' name="_Twitter" value={_Twitter}
							onChange={handleChangeTwitter} />
						{error && <div id='error-message'>{error}</div>}

					</div>

					<div className='inputSection'>
						<div>LINK <img className='iconsR' src={tg} /></div>
						<input className='inputR2' placeholder='TELEGRAM' name="_Telegram" value={_Telegram}
							onChange={handleChangeTG} />
						{error2 && <div id='error-message'>{error2}</div>}
					</div>

					<div className='inputSection'>
						<div className='flexIn'>
							<div id='star'>*</div> LINK
							{selectedLink === 'PUMP.FUN' ? (
								<img className='iconsR' src={pump} alt="PUMP.FUN" />
							) : (
								<img className='iconsR' src={ms} alt="MOON SHOT" />
							)}
						</div>
						<select className='inputR2-1' name="linkSelect" value={selectedLink} onChange={handleSelectChange}>
							<option value="PUMP.FUN">PUMP.FUN</option>
							<option value="MOON SHOT">MOON SHOT</option>
						</select>
						<input
							required
							className='inputR2-2'
							placeholder={`${selectedLink} LINK`}
							name={selectedLink === 'PUMP.FUN' ? "_Pump" : "_Moonshot"}
							value={selectedLink === 'PUMP.FUN' ? _Pump : _Moonshot}
							onChange={handleInputChange2}
						/>
						{errorMessage && <div id='error-message'>{errorMessage}</div>}
					</div>

					<div className='inputSection'>
						<div className='flexIn'><div id='star'>*</div> LINK <img className='iconsR' src={sol} /></div>
						<input required className='inputR2' placeholder='SOL SCAN' name="_SolScan" value={_SolScan}
							onChange={handleChangeSol} />
						{error3 && <div id='error-message'>{error3}</div>}
					</div>

					{/*walletAddress === null ?
						<div className='memeC' onClick={connectWallet} disabled>CONNECT WALLET</div> :

						<button type="submit">LIST NOW</button>*/}

					{isMobile && !_isPhantom ?
						<button class="mintBtn" onClick={phantomMob}>CONNECT WITH <br /> PHANTOM MOBILE VERISON</button> :
						!walletAddress ?
							<button class="mintBtn" onClick={connectWallet}>CONNECT WALLET</button> :
							<button type="submit">LIST NOW</button>
					}

					<div className='txtR'><span className='colorTxt1'>50%</span> OFF for the first 500 projects</div>
					<div className='txtR'><span className='colorTxt2'>Cost</span> is 0.50 <span className='colorTxt3'>Sol</span></div>
				</form>
			</div >

			{_paymentStatus ?
				<div className='popup'>
					<div className="response-message">
						<div className='txtMsg'>Payment sent successfully <img src={check} /></div>
						<div className='txtMsg3'>Waiting for the Information...</div>
					</div>
				</div > : null}

			{/*responseMessage ?
				<div className='popup'>
					<div className="response-message">
						<div className="close" onClick={closediv}>✖</div>
						<div className='txtMsg'><span className='err'>Error Occurred in payment</span></div>
						<div className='txtMsg2'><a href="mailto:contact@pumpfound.com" target="blank">Please contact the support center</a></div>
					</div>
				</div > : null*/}

			{_balance ?
				<div className='popup'>
					<div className="response-message">
						<div className="close" onClick={closediv}>✖</div>
						<div className='txtMsg'><span className='err'>Insufficient Balance</span></div>
						<div className='txtMsg4'>Your wallet does not have enough SOL to complete this transaction</div>
					</div>
				</div > : null}

			{connectDivMessage ?
				<div className='popup'>
					<div className="response-message">
						<div className="close" onClick={closediv}>✖</div>
						<div className='txtMsg'><span className='err'>Please connect your wallet</span></div>
						<div className='txtMsg2'>Solana</div>
					</div>
				</div > : null}

			{_submit ?
				<div className='popup'>
					<div className="response-message">
						<div className='txtMsg'>Information sent successfully <img src={check} /></div>
						<div className='txtMsg2' onClick={() => window.location.href = '/'}>Back to main page</div>
					</div>
				</div > : null}

			{isLoading ?
				<div className='popup'>
					<div className="response-message">
						<div className='txtMsg'>Please wait...</div>
					</div>
				</div > : null}

		</div>
	);
};

export default Register;
