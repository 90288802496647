import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Home from "../src/Pages/Home";
import Register from "../src/Pages/register";
import Profile from './Pages/profile';
import Admin from './Pages/admin';
import AdsEnter from './Pages/adsEnter';

function App() {

	return (
		<div>
			<BrowserRouter>
				<Routes>

					<Route path='/' element={<Home />} />
					<Route path='/register' element={<Register />} />
					<Route path='/profile' element={<Profile />} />
					<Route path='/advertisements' element={<AdsEnter />} />

				</Routes>
			</BrowserRouter>

		</div>

	)
}

export default App;
